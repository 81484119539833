import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import * as React from "react";
import {
  BlogList,
  InterviewList,
  Layout,
  Seo,
  SlideList,
} from "../components/";
import { mq } from "../styles/GlobalStyles";

interface MarkdownRemarkData {
  id: string;
  frontmatter: {
    slug: string;
    date: Date;
    title: string;
    featuredImgAlt: string | null;
    hero: {
      childImageSharp: {
        gatsbyImageData: object;
      } | null;
    } | null;
    heroAlt: string | null;
  };
  featuredImg: {
    childImageSharp: {
      gatsbyImageData: object;
    } | null;
  } | null;
}

interface InterviewsYamlData {
  site: {
    members: string[];
    title: string;
    url: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: object;
      } | null;
    } | null;
    heroAlt: string | null;
  };
}

interface SlidesYamlData {
  site: {
    title: string;
    url: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: object;
      } | null;
    } | null;
    heroAlt: string | null;
  };
}

interface IndexData {
  allMarkdownRemark: {
    totalCount: number;
    edges: [
      {
        node: MarkdownRemarkData;
      }
    ];
  };
  allInterviewYaml: {
    edges: [
      {
        node: InterviewsYamlData;
      }
    ];
  };
  allSlidesYaml: {
    edges: [
      {
        node: SlidesYamlData;
      }
    ];
  };
}

const Section = styled.div({
  display: "flex",
  flexDirection: "column",
});

const linkStyle = css(
  mq({
    display: "flex",
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "center",
    fontWeight: "bold",
    alignItems: "center",
    padding: "10px",
    width: "253px",
    height: "45px",
    color: "#E60012",
    background: "rgba(230, 0, 18, 0.06)",
    borderRadius: "37px",
    marginBottom: ["48px", "56px"],
    textDecoration: "none",
    fontSize: ["0.9em", "1em"],
    span: {
      transition: "all .3s",
      marginLeft: "8px",
    },
    "&:hover": {
      span: {
        transform: "skew(40deg) translateX(10px)",
        transition: "all .3s",
      },
    },
  })
);

const Arrow = styled.span(
  mq({
    width: "20px",
    height: "7px",
    borderBottom: "2px solid #E60012",
    borderRight: "2px solid #E60012",
    transform: "skew(40deg)",
  })
);

const H2 = styled.h2(
  mq({
    fontSize: ["2.5em", "3.5em"],
    color: "#2E3C4D",
    marginBottom: "12px",
    fontFamily: `"Manrope", sans-serif`,
  })
);

const P = styled.p(
  mq({
    fontSize: ["0.8em", "1em"],
    fontWeight: ["bold"],
    color: "#999999",
    marginBottom: ["24px", "32px"],
    marginTop: "-12px",
    letterSpacing: ["1px"],
  })
);

const IndexPage = ({ data }: { data: IndexData }) => {
  return (
    <Layout>
      <>
        <Seo title="トップ" />
        <Section>
          <H2>Tech Blog</H2>
          <BlogList lists={data.allMarkdownRemark.edges} feature={true} />
          <Link to={`/entries`} css={linkStyle}>
            ブログ一覧へ
            <Arrow></Arrow>
          </Link>
        </Section>
        <Section>
          <H2>Interview</H2>
          <P>メドレーで働くメンバーのインタビューをご紹介します。</P>
          <InterviewList lists={data.allInterviewYaml.edges} />
          <Link to={`/interviews`} css={linkStyle}>
            インタビュー一覧へ
            <Arrow></Arrow>
          </Link>
        </Section>
        <Section>
          <H2>Company Introduction</H2>
          <SlideList lists={data.allSlidesYaml.edges} />
        </Section>
      </>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 7
      skip: 0
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          featuredImg {
            childImageSharp {
              gatsbyImageData(
                width: 436
                height: 260
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          frontmatter {
            title
            slug
            date(formatString: "YYYY-MM-DD")
            featuredImgAlt
            hero {
              childImageSharp {
                gatsbyImageData(
                  width: 436
                  height: 260
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            heroAlt
          }
        }
      }
    }
    allInterviewYaml(limit: 3) {
      edges {
        node {
          id
          site {
            members
            title
            url
            hero {
              childImageSharp {
                gatsbyImageData(width: 337, height: 190, layout: CONSTRAINED)
              }
            }
            heroAlt
          }
        }
      }
      totalCount
    }

    allSlidesYaml(limit: 3) {
      edges {
        node {
          id
          site {
            title
            url
            hero {
              childImageSharp {
                gatsbyImageData(width: 700, height: 444, layout: CONSTRAINED)
              }
            }
            heroAlt
          }
        }
      }
      totalCount
    }
  }
`;
